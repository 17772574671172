.wrap-buttons {
    margin-top: 10px;
}
.shipping-title-text {
    font-size: 35px;
    color: #384952;
    margin-bottom: 20px;
    font-weight: 600;
}
.wrap-checkbox-text {
    display: flex;
    align-items: center;
    font-size: 12px;
}

@media (max-width: 540px) {
    .wrap-selector-country-shipping .MuiFormControl-root {
        margin-top: 20px;
    }
 }