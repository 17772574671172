.selector-currency-select {
    width: 110px;
    height: 30px;
}
/* .selector-currency-menu-item {
    
} */
.selector-currency-select .MuiOutlinedInput-input  {
    padding: 0;
    padding-left: 10px;
}
.MuiSelect-select:focus {
    background-color: white !important
}
.selector-currency-select .MuiSelect-iconOutlined {
    right: 0;
}
.selector-currency-wrap-selector {
    margin: 8px;
}
