.wrap-block-billing-invoice {
  width: 100%;
}
.wrap-info-block {
  /* margin-top: 50px; */
  width: 100%;
}
.title-membership-plan {
  text-transform: uppercase;
  /* color: silver; */
  margin-bottom: 5px;

  font-weight: 500;
}
.wrap-block-membership-plan {
  /* border: 1px solid #e2e2e2; */
  padding: 20px 10px;
  display: flex;
  position: relative;
  /* box-shadow: 0 0 3px rgba(0,0,0,0.5); */
}
.annotation-to-bill-block {
  margin-top: 5px;
  font-size: 11px;
  color: silver;
}
.balance {
  padding-top: 10px;
}
.adding-to-summary-option-title {
  font-style: italic;
  font-size: 14px;
  font-weight: normal;
}
.title-billing-invoice {
  /* color: silver;
    font-size: 14px; */
  font-size: 20px;
  padding-bottom: 5px;
  display: flex;
  align-items: baseline;
  text-transform: uppercase;
}
.block-plan-billing-invoice {
  padding-bottom: 25px;
}
.type-block-membership-plan {
  padding-bottom: 10px;
}
.title-plan-block-membership-plan {
  display: flex;
  justify-content: space-between;
}
.type-block-membership-plan span {
  font-weight: bold;
}
.title-bill-block-membership-plan {
  justify-content: space-between;
}
.wrap-table-billing-invoice {
  width: 100%;
  margin-top: 50px;
}
.table-cell-billing-invoice {
  /* font-weight: 600  !important; */
  /* color: silver !important */
  font-family: inherit !important;
  font-weight: normal !important;
  /* color: white !important; */
  text-transform: uppercase;
  font-size: 15px !important;
  /* background: #333; */
}
/* .table-thead-titles {
  background: #333;
} */
.table-cell-notification {
  color: #333 !important;
  background-color: white !important;
}
.image-billing-invoice {
  width: 25px;
  height: 23px;
  background-size: cover;
  margin-right: 10px;
}
.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.wrap-summary-options {
}
.summary-option {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.summary-option-title {
  font-weight: 500;
}
.summary-option-value {
}
.wrap-summary {
  /* width: 50%; */
}
.summary-total {
  border-top: 1px solid #e2e2e2;
  padding-top: 10px;
}
.wrap-responsive-table-billing-invoice {
  display: none;
}
.wrap-non-responsive-table-billing-invoice {
  display: block;
}

.responsive-table-table-row {
  display: flex !important;
  /* flex: 1; */
  flex-direction: column;
}
.electronic-payment {
  border-bottom: 1px solid black;
}
.responsive-table-title {
  font-weight: 600;
}
.cell-electronic-payment {
  border-bottom: 1px solid white !important;
}
.wrap-some-data-responsive-table {
  display: flex;
  align-items: center;
}
.wrap-some-data-responsive-table .responsive-table-title {
  padding-right: 10px;
}
.last-payment-block-wrap {
  width: 50%;
  display: flex;
  padding: 0px 40px;
}
.wrap-block-plan-billing-invoice {
  width: 50%;
}
.last-payment-block {
  width: 100%;
  max-height: 350px;
  background: #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 60px;
  /* margin-right: 25px; */
}
.wrap-price-due {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}
.payment-price {
  font-size: 50px;
}
.last-payment-text {
  margin-top: 20px;
  margin-bottom: 30px;
}
.responsive-table-row-event-log-body {
  /* background: #e2e2e2 !important; */
  border-bottom: 1px solid black;
}
/* .table-responsive .responsive-table-row-event-log-body :nth-child(2n) {
  background: #e2e2e2 !important;
  border-bottom: 1px solid black;
} */

@media (max-width: 1270px) {
  .wrap-block-membership-plan {
    flex-direction: column;
  }
  .wrap-block-plan-billing-invoice {
    width: 100%;
  }
  .last-payment-block-wrap {
    width: 100%;
    margin-top: 30px;
    padding: 0;
  }
  .last-payment-block {
    padding: 20px 150px;
    margin-right: 0;
  }
}

@media (max-width: 1098px) {
  .wrap-responsive-table-billing-invoice {
    display: block;
  }
  .wrap-non-responsive-table-billing-invoice {
    display: none;
  }
}

@media (max-width: 930px) {
  .last-payment-block {
    padding: 20px 70px;
  }
}

@media (max-width: 800px) {
  .title-bill-block-membership-plan {
    flex-direction: column;
  }
  .title-bill-block-membership-plan button {
    margin-top: 10px;
  }
  .last-payment-block {
    padding: 20px 30px;
  }
}

@media (max-width: 540px) {
  .wrap-responsive-table-billing-invoice {
    display: block;
  }
  .wrap-non-responsive-table-billing-invoice {
    display: none;
  }
  .title-billing-invoice {
    font-size: 16px;
  }
}
