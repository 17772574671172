.input-title {
    font-weight: 700;
}
.link {
    text-decoration: none;
    color: white
}
.wrap-block-email {
    width: 100%;
}
.button-save-email {
    margin-right: 30px;
}