.wrap-content {
  display: flex;
  justify-content: center;
  width: 100%;
}
.content-payment-add {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.icon-lock {
  border: 2px solid #f50057;
  border-radius: 50px;
  padding: 3px;
}
.wrap-card-block {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  flex-direction: row;
  padding: 20px 10px;
  border: 1px solid black;
  min-width: 70%;
  margin-bottom: 30px;
  cursor: pointer;
}
.card-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.card-name-icons {
  width: 80%;
}
.link-payment-add {
  color: transparent;
  text-decoration: none;
}
.link-cancel {
  text-decoration: none;
  color: white;
}
.wrap-payment-add {
  width: 100%;
}
