.wrap-container-plans {
  display: flex;
  flex-direction: column;
}
.wrap-container-plans :nth-child(2n) {
  flex-direction: row-reverse;
}
.container-plans {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-bottom: 20px;
  color: #384952;
}

.centered-header {
  margin: 80px auto;
}

.container-plans-title {
  font-size: 50px;
  font-weight: bold;
}
.container-plans-description {
  color: #718096;
  font-size: 21px;
}
.wrap-filter-container-plans {
  display: flex;
  justify-content: flex-end;
}
.container-plans-price {
  font-size: 65px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.wrap-plans {
  padding: 40px;
  width: 100%;
}
.image-plan {
  width: 100%;
  max-width: 500px;
  /* width: 500px; */
  /* height: 400px; */
}
.container-plans-price-description {
  width: 305px;
  margin-top: 20px;
  background: none;
  padding: 0;
  border-radius: 16px;
  color: #718096;
  text-align: left;
  font-size: 14px;
}
.wrap-title-change-plan {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  text-transform: uppercase;
}
.without-rate {
  font-size: 16px;
}
.container-plans-texts {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-plans-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}
@media (max-width: 540px) {
  .container-plans {
    flex-direction: column;
  }
  .container-plans-image {
    display: none;
  }
  .container-plans-title {
    font-size: 30px;
  }
  .container-plans-description {
    font-size: 16px;
  }
  .container-plans-price {
    font-size: 35px;
  }
  .container-plans-price-description {
    width: 200px;
    margin-top: 10px;
    font-size: 10px;
  }
  .container-plans-texts {
    width: 100%;
  }
}

@media (max-width: 900px) {
  /* .container-plans {
        flex-direction: column;
    }
    .container-plans-image {
        display: none;
    } */
  .container-plans-title {
    font-size: 40px;
  }
  .container-plans-description {
    font-size: 18px;
  }
  .container-plans-price {
    font-size: 35px;
  }
  .container-plans-price-description {
    width: 200px;
    margin-top: 10px;
    font-size: 10px;
  }
  .image-plan {
    /* width: 400px; */
    /* height: 300px; */
  }
  /* .container-plans-texts {
        width: 100%;
    } */
}
/* @media (max-width: 540px) {
    .container-plans {
        flex-direction: column;
    }
    .container-plans-image {
        display: none;
    }
    .container-plans-title {
        font-size: 30px;
    }
    .container-plans-description {
        font-size: 16px;
    }
    .container-plans-price {
        font-size: 35px;
    }
    .container-plans-price-description {
        width: 200px;
        margin-top: 25px;
        font-size: 10px;
    }
    .container-plans-texts {
        width: 100%;
    }
} */
