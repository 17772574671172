.wrap-content {
  display: flex;
  justify-content: center;
  width: 100%;
}
.title {
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.content-payment-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.icon-lock {
  border: 2px solid #f50057;
  border-radius: 50px;
  padding: 3px;
}
.wrap-button-add-payment {
  margin-top: 30px;
  margin-bottom: 30px;
}
.linkPayment {
  text-decoration: none;
  color: #f50057;
}
.link-cancel {
  text-decoration: none;
  color: white;
}
.wrap-payment-info {
  width: 100%;
}

.wrap-view-payment-method {
  width: 100%;
  padding-top: 30px;
}
.card-view-payment-method {
  display: flex;
}
.actions-view-payment-method {
  display: flex;
  width: 30%;
  justify-content: space-between;
}
.action-buttons-view-payment-method {
  display: flex;
  padding-left: 15px;
}
.status-view-payment-method {
  border: 1px solid black;
}
.status-view-payment-method div {
  padding: 5px;
}
.payment-method {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
}

@media (max-width: 540px) {
  .payment-method {
    flex-wrap: wrap;
    justify-content: end;
  }
  .actions-view-payment-method {
    width: auto;
    padding-top: 5px;
  }
}
