.item-cart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid silver;
  padding-top: 5px;
  padding: 15px;
  color: #384952;
}
.item-image-title {
  display: flex;
  flex-direction: row;
  width: 70%;
}
.item-description-cart {
  color: #718096;
  font-size: 14px;
}
.item-quantity {
  display: flex;
  padding: 5px 10px;
  border: 1px solid silver;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}
.item-quantity :nth-child(2) {
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}
.item-quantity-checkout {
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}
.add-remove-item {
  width: 10px;
  height: 10px;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item-quantity-none {
  display: none;
}
.item-title-cart {
  font-size: 18px;
  color: #384952;
  font-weight: bold;
}
.title-cart {
  font-size: 24px;
  font-weight: bold;
  /* border-bottom: 1px solid silver; */
  margin-bottom: 70px;
  color: #384952;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.total-sum {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  justify-content: space-between;
  font-size: 23px;
  font-weight: bold;
  color: #384952;
  padding-bottom: 20px;
}
.total-taxes {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  color: #384952;
  margin-bottom: 10px;
}
.total-sum :nth-child(2) {
  font-weight: bold;
  padding-left: 10px;
  /* font-size: 20px; */
}
.wrap-content-cart {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 100px;
}
.button-checkout {
  width: 100%;
}
.wrap-content-cart-checkout {
  background-color: #f8f9fa;
  padding: 20px 20px 10px 20px;
  /* margin-right: 20px; */
  height: 100%;
}
.wrap-button-checkout {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}
.image-wrap {
  width: 100px;
  height: 100px;
  border: 1px solid silver;
  border-radius: 10px;
  padding: 5px;
  margin-right: 10px;
}
.item-price-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.add-remove-item-empty {
  width: 24px;
}
.wrap-add-remove-delete {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.delete-button {
  width: 20px;
  height: 20px;
}
.item-cart-price {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}
.total-item-sum {
  /* padding-top: 20px; */
  font-size: 18px;
  font-weight: 500;
  display: flex;
}
.total-taxes-sum {
  padding-top: 20px;
  font-size: 14px;
  /* font-weight: 500; */
  display: flex;
}
.total-taxes-sum :nth-child(2) {
  padding-left: 5px;
}
.total-item-sum :nth-child(2) {
  padding-left: 5px;
}
.empty-cart-text {
  color: #384952;
  font-size: 20px;
}
@media (max-width: 1000px) {
  .wrap-content-cart-checkout {
    padding: 20px 20px 10px 20px;
  }
  .list-checkout {
    display: block !important;
  }
}
@media (max-width: 540px) {
  .wrap-content-cart {
    padding: 20px 10px;
  }
  .item-cart {
    padding: 5px 0 5px 0;
  }
  .item-title-cart {
    font-size: 14px;
  }
  .item-cart-price {
    font-size: 16px;
  }
  .total-item-sum {
    font-size: 16px;
  }
  .total-item-sum-addiction {
    font-size: 12px;
  }
}
