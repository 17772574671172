.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  padding-right: 0 !important;
  overflow: visible !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* div,p {
  color: #282c34;
} */
input {
  color: #384952 !important;
}
.MuiTypography-subtitle1 {
  color: #384952 !important;
}
.MuiStepLabel-label.MuiStepLabel-active {
  color: #384952 !important;
}
li {
  color: #384952 !important;
}
div {
  color: #384952;
}
.MuiInputBase-input {
  color: #384952 !important;
}
.MuiTableCell-body {
  color: #384952 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.wrap-main-main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.main-main {
  display: flex;
  justify-content: center;
  flex-direction: "row";
  flex: 1;
  margin-top: 55px;
}
.main-main-sign-in {
  display: flex;
  justify-content: center;
  flex-direction: "row";
  flex: 1;
  margin-top: 0px;
}
.background-wrap-section {
  /* width: 100%;
  height: 94vh;
  background-size: 100% 100%; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.logo-wrap-section {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
.wrap-block-with-img {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.categories-plans-subtitle {
  font-size: 21px;
}

@media (max-width: 540px) {
  .categories-plans-subtitle {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .categories-plans-subtitle {
    font-size: 18px;
  }
}
@media (max-width: 800px) {
  .main-main {
    margin-top: 103px;
  }
  .main-main .background-wrap-section {
    margin-top: -48px
  }
}
@media (max-width: 660px) {
  .main-main {
    margin-top: 55px;
  }
}
@media (max-width: 400px) {
  .main-main {
    margin-top: 103px;
  }
  .main-main .background-wrap-section {
    margin-top: -48px
  }
}
