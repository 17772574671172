.wrap-change-plan-account {
  width: 100%;
}
.wrap-plan-list-account {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.plan-list-account {
  width: 700px;
  padding-bottom: 15px;
}
.wrap-subtitle-plan-account {
  display: flex;
  justify-content: center;
}
.wrap-title-header-change-plan {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* .cart-job-title-wrap-change-rate
  .cart-job-title-change-rate
  .cart-job-text-current-change-rate {
  color: inherit !important;
} */

.cart-job-title-wrap-change-rate {
  color: inherit;
}
.cart-job-title-change-rate {
  color: inherit;
  font-weight: 100;
}
.cart-job-text-current-change-rate {
  font-weight: 600;
}
.cart-job-subtitle-change-rate {
  color: inherit;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  /* width: 50%; */
}

@media (max-width: 900px) {
  .plan-list-account {
    width: 100%;
  }
}
