.root-sign-up {
    width: 100%;
}
.input-sign-in {
    color: white;
    border-color: white
}
.title-checkout-create {
    font-size: 25px;
    color: #384952;
    margin-bottom: 20px;
    font-weight: 600;
}
.title-checkout-have-an-account {
    font-size: 35px;
    color: "#384952";
    font-weight: 600;
    margin-top: 10;
    color: #384952;
}
.text-sign-in {
    font-size: 18px;
    color: #384952;
    padding-bottom: 10px;
}
.sign-text {
    text-decoration: underline;
    cursor: pointer
}
.text-error {
    color: red;
}
.wrap-button-signup-form {
    padding: 0 7px;
    width: 100%;
}
