.billing-title-text {
  font-size: 35px;
  color: #384952;
  margin-bottom: 20px;
  font-weight: 600;
  /* font-weight: bold; */
}
.wrap-data-credentials {
  color: #384952;
  /* font-weight: bold */
}
.image-payment {
  width: 50px;
  height: 50px;
  background-size: cover;
}
.wrap-card-data-select-payment {
  display: flex;
}
.card-wrap-images-title {
  display: flex;
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 10px;
}
.card-wrap-images {
  display: flex;
  justify-content: space-around;
}
.card-credit-title {
  color: #384952;
  margin-left: 100px;
}
.wrap-almost-there-credit-cards {
  margin-bottom: 20px;
}
.almost-there-wrap {
  margin-top: 30px;
  font-size: 20px;
  color: #384952;
  margin-bottom: 20px;
}
.edit-button {
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  width: 25px;
}
.wrap-buttons {
  padding-top: 20px;
}
.wrap-payment-details {
  padding: 10px;
}
@media (max-width: 540px) {
  .card-job-minimal__location-conatiner {
    width: 70%;
  }
  .image-payment {
    width: 35px;
    height: 35px;
  }
  .card-wrap-images {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .image-payment {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 1000px) {
  .image-payment {
    width: 30px;
    height: 30px;
  }
}
