.root-sign-in {
    width: 100%;
}
.title-checkout-login {
    font-size: 35px;
    color: #384952;
    margin-bottom: 20px;
    font-weight: 600;
}
.button-forgot-password {
    padding-top: 15px;
}