.list {
  width: 650px;
}
.fullList {
  width: auto;
}
.list-checkout {
  width: 100%;
}

@media (max-width: 540px) {
  .list {
    width: 100vw;
  }
  .list-checkout {
    width: 100vw;
  }
  .list-burger-menu {
    width: 55vw;
  }
}
