.wrap-filter-content {
    min-width: 400px;
    min-height: 600px;
    padding: 20px;
}
.wrap-button-filter-content {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 540px) {
    .wrap-filter-content {
        min-width: 300px;
        min-height: 450px;
        padding: 20px;
    }
}
