.Toastify__progress-bar--default {
    background: #3f51b5 ;
}
.title-notification {
    color: #384952;
    font-weight: 700;
    padding-bottom: 3px;
    font-size: 20px;
}
.description-notification {
    font-size: 12px;
}
.Toastify__toast-container--top-right {
    top: 5em;
}
.padding-notification {
    padding-top: 10px;
    padding-bottom: 5px;
}
.button-notification {
    color: #3f51b5
}