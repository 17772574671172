.title-notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link-notification {
    text-decoration: none;
    color: white;
}
.wrap-notification {
    width: 100%;
}
.non-notifications {
    text-align: center;
    font-size: 20px;
}