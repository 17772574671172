.wrap-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content {
    display: flex;
    justify-content: center;
    width: 70%;
}
.title {
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px; 
}
.input-title {
    font-weight: 700;
}
.link-cancel {
    text-decoration: none;
    color: white
}
.button-payment-data {
    width: 100%;
    margin-top: 10;
}
.form-control-payment-data {
    width: 45%;
}
.button-save-payment-data {
    padding-top: 25px;
} 