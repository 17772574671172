.wrap-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(255,255,255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
