.title-order {
    font-size: 20px;
    color: #384952;
    font-weight: bold;
}
.wrap-shipping-payment {
    margin-top: 30px;
    display: flex ;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid silver;
}
.item-value {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
.wrap-payment {
    width: 35%;
}
.shipping-value {
    margin-top: 10px
}