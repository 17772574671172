.root-stepper-checkout {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
}
.button {
}
.instructions {
}
.cart-details-wrap {
  flex: 1;
}
.wrap-main-stepper {
  width: 60%;
  margin-right: 20px;
  padding: 10px 110px;
}

@media (max-width: 1000px) {
  .cart-details-wrap {
    display: none;
  }
  .wrap-main-stepper {
    width: 100%;
    margin-right: 0;
    padding: 10px 10px;
  }
}
/* @media (max-width: 900px) {
    .cart-details-wrap {
        display: none;
    }
    .wrap-main-stepper {
     width: 100%;
     margin-right: 0;
     padding: 10px 10px;
    }
} */
