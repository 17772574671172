.input-title-security {
    font-weight: 700;
}
.switch-title {
    font-weight: 700;
}
.title-security-cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.link-security {
    text-decoration: none;
    color: white;
}