.wrap-plan-select-group {
    width: 200px;
    height: 40px;
}
.plan-select-group {
    width: 100%;
    height: 40px;
}
.select-group {
    height: 40px;
}
.wrap-plan-select-group .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
    color: #303f9f;
}
.select-group .MuiOutlinedInput-notchedOutline{
    border: none;
}