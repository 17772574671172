.root-hero {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color:#303f9f;
}
.text-white {
    color: white;
    padding-left: 65px;
}
.title {
    font-weight: bold;
    color: white;
}
.section-wrap {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 540px) {
    .title {
        color: white;
        padding-left: 65px;
        font-size: 20px;
    }
}