.navigation-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
  min-height: 40px;
  /* box-shadow: 0 8px 6px -6px black; */
  padding: 10px 5px 5px 5px;
  /* border-bottom: 1px solid silver; */
  /* box-shadow: 0px 15px 10px -15px #111;
    background-color: #f5f5f5; */
}
.wrap-logout {
  display: flex;
  flex-direction: row;
}
.link-navigation {
  text-decoration: none;
  color: #303f9f;
}
.cart-wrap {
  margin-right: 10px;
}
.cart-wrap-mobile-checkout {
  display: none;
}
.wrap-links-mobile {
  display: none;
}
.wrap-links-web-logout {
  display: flex;
  align-items: center;
}
.wrap-logo {
  flex: 1;
  height: 30px;
  display: flex;
  /* justify-content: center; */
  padding-left: 30px;
  align-items: center;
}
.container-logo {
  height: 100%;
  width: 125px;
}
.main-navigation {
  position: fixed;
  z-index: 199;
  background: white;
  width: 100%;
}
.makeStyles-root-3 {
  width: auto;
}
.logo {
  cursor: pointer;
  width: auto !important;
}
.display-none {
  display: none;
}
.wrap-links-web {
  display: flex;
}
.wrap-logo-bigger {
  flex: 1;
  height: 40px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrap-logo-for-small-devices {
  display: none;
}
@media (max-width: 1000px) {
  .cart-wrap-mobile-checkout {
    display: block;
    position: absolute;
    right: 1px;
  }
}
@media (max-width: 810px) {
  .wrap-logo-for-small-devices {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: 10px;
  }
  .wrap-logo {
    display: none;
  }
  .navigation-panel {
    justify-content: flex-end;
  }
}
@media (max-width: 660px) {
  .navigation-panel {
    flex-direction: row;
    /* padding: 0; */
    align-items: center;
    justify-content: space-between;
  }
  .wrap-logo {
    padding: 0;
  }
  .wrap-logo-bigger {
    /* margin-right: 48px; */
  }
  .link-navigation {
    font-size: 15px;
  }
  .wrap-logout {
    justify-content: flex-end;
  }
  .wrap-links-web {
    display: none;
  }
  .wrap-links-mobile {
    display: flex;
    justify-content: flex-start;
  }
  .wrap-logo {
    display: flex;
    height: 25px;
  }
  .wrap-logo-for-small-devices {
    display: none;
  }
}
@media (max-width: 400px) {
  .wrap-logo-for-small-devices {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: 10px;
  }
  .wrap-logo {
    display: none;
  }
  .display-none {
    display: none;
  }
}
