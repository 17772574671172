.input-title {
  font-weight: 700;
}
.wrap-block {
  /* border-top: 2px solid #e2e2e2; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 15px;
}
.first {
  border: none;
}
.wrap-blocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 9px;
}
.name-block {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 1.2em;
  background: black;
  padding-top: 6px;
  padding-left: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.name-block div {
  color: white;
}
.container {
  display: flex;
  flex: 1;
  width: 100%;
}
.wrap-options {
  color: blue;
  /* font-size: 13px; */
}
.option {
  padding-bottom: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.border {
  border-top: 1.5px solid silver;
  padding-top: 5px;
}
.account-header-to-uppercase {
  text-transform: uppercase;
}

.wrap-acc-content-block .content-block {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.content-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.value {
  font-weight: bold;
}
.link-text {
  cursor: pointer;
  text-decoration: none;
  color: #303f9f;
}
.link-navigation-account {
  cursor: pointer;
  text-decoration: none;
  color: #303f9f;
}
.plan-name-option {
  font-weight: 500;
  padding-right: 10px;
}
.wrap-plans-option {
  display: flex;
  flex-direction: column;
}
.wrap-card-data-option {
  display: flex;
  flex-direction: column;
}
.wrap-card-data-option div {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.warning-to-delete {
  color: #718096;
  font-size: 11px;
}
.wrap-plan-pulse {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.rate-name-option {
  padding-right: 10px;
}
.wrap-rate-option-circles {
  display: flex;
  align-items: center;
}

/* for pusle icons */
.green {
  background: #30a026;
}
.yellow {
  background: #ffc400;
}
.red {
  background: #e63232;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}
.pulse {
  animation: pulse-animation 2s infinite;
}
.wrap-acc-content-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.acc {
  width: 25%;
}
.acc-due {
  margin-bottom: 10px;
}
.acc-title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

@media (max-width: 540px) {
  /* .wrap-options {
    font-size: 11px;
  }
  .name-block {
    font-size: 13px;
  } */
  .wrap-button-plan-option button {
    font-size: 11px;
  }
  .wrap-block {
    flex-direction: column;
  }
  .content-block {
    width: 100%;
  }
  .wrap-plan-pulse {
    flex-direction: column;
    align-items: unset;
  }
  .rate-name-option {
    font-size: 11px;
  }
}
@media (max-width: 900px) {
  /* .wrap-button-plan-option button {
    font-size: 11px;
  }
  .wrap-block {
    flex-direction: column;
  }
  .content-block {
    width: 100%;
  } */
  .wrap-plan-pulse {
    flex-direction: column;
    align-items: unset;
  }
  .rate-name-option {
    /* font-size: 11px; */
  }
  .wrap-acc-content-block {
    flex-direction: column;
  }
  .acc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .wrap-acc-content-block .content-block {
    margin-top: 20px;
    width: 100%;
  }
}
@media (max-width: 720px) {
  /* .wrap-button-plan-option button {
    font-size: 11px;
  }
  .wrap-block {
    flex-direction: column;
  }
  .content-block {
    width: 100%;
  } */
  .wrap-plan-pulse {
    flex-direction: column;
    align-items: unset;
  }
  .rate-name-option {
    font-size: 11px;
  }
}
@media (max-width: 375px) {
  .wrap-options {
    font-size: 13px;
  }
  .wrap-rate-option-circles {
    flex-direction: column;
    align-items: unset;
  }
}
@media (max-width: 320px) {
  .wrap-options {
    font-size: 12px;
  }
}
