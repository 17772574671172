.categories-wrap-title-description {
  color: #384952;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
}
.categories-plans-title {
  font-size: 50px;
  font-weight: bold;
}
.categories-plans-description {
  color: #718096;
  font-size: 21px;
}
.header-supplemental-plans-button {
  position: absolute;
  right: 0;
}
.categories-plans-error {
  font-size: 50px;
  margin-top: 80px;
}
.supplemental-plans-wrap {
  width: 100%;
  margin-top: 30px;
}
.paper-supplemental-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.header-supplemental-plans {
  font-size: 25px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-supplemental-plan {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e2e2e2;
  align-items: center;
  flex-direction: row;
}
.wrap-blocks-supplemental-plans {
  width: 100%;
}
.add-supplemental-plan {
}
.name-description-supplemental-plan {
  width: 65%;
}
.amount-supplemental-plan {
  font-size: 20px;
}
.amount-add-supplemental-plan {
  width: 30%;
  display: flex;
  justify-content: space-between;
}
.description-supplemental-plan {
}
.name-supplemental-plan {
  font-size: 20px;
}
.wrap-add-all-options {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 540px) {
  .categories-plans-title {
    font-size: 30px;
  }
  .categories-plans-description {
    font-size: 16px;
  }
  .categories-plans-error {
    font-size: 20px;
  }
}
@media (min-width: 960px) {
  .MuiGrid-grid-md-3 {
    max-width: 400px !important;
  }
}
@media (max-width: 760px) {
  .block-supplemental-plan {
    flex-direction: column;
    align-items: unset;
  }
  .name-description-supplemental-plan,
  .amount-add-supplemental-plan {
    width: 100%;
  }
  .amount-add-supplemental-plan {
    padding-top: 20px;
    align-items: center;
  }
}
