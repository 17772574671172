.input-title {
    font-size: 700;
}
.form-control {
    min-width: 120px;
    width: 100%;
}
.select-empty {

}
.link {
    text-decoration: none;
    color: white;
}
.style-grid-general {
    display: flex;
}