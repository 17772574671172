.modal-modal-window {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper-modal-window {
  width: 500px;
  height: 350px;
  display: flex;
  flex-direction: column;
}
.wrap-message-modal-window {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}
.line-green-modal-window {
  background-color: greenyellow;
  width: 100%;
  height: 50px;
}
.line-red-modal-window {
  background-color: red;
  width: 100%;
  height: 50px;
}
.dialog-modal-window {
  display: flex;
}
.MuiDialog-scrollPaper .MuiDialog-paperScrollPaper {
  min-width: 400px;
}

@media (max-width: 400px) {
  .MuiDialog-scrollPaper .MuiDialog-paperScrollPaper {
    min-width: auto;
  }
}
