.wrap-fontColors {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.wrap-fontColors-button {
  display: flex;
  align-items: center;
}
.block-fontColor {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;
  border: 1px solid black;
}
.wrap-select-config {
  padding-top: 20px;
}
.MuiTab-wrapper {
  color: white;
}
.wrap-configure {
  width: 100%;
  padding: 20px;
}
.wrap-configure .MuiTabs-fixed {
  height: 55px;
}
.wrap-configure .MuiTabs-centered {
  height: 100%;
}
.wrap-block-upload-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
}
.title-upload-img {
  font-size: 20px;
  padding-bottom: 20px;
}
.wrap-buttons-upload-img {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.upload__image-wrapper {
  width: 100%;
}
